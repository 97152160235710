import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.location.href = "https://treno-elasticsearch.auth.ap-southeast-1.amazoncognito.com/login?response_type=code&client_id=5hek69svmipf0c0a7tsk3i3gof&redirect_uri=https://search-treno-elasticsearch-jrp7ehnnmoaq5kiznodd2ckn34.ap-southeast-1.es.amazonaws.com/_plugin/kibana/app/kibana&state=19bdd0ee-6b70-4d74-99b4-b01cdfa6668b";